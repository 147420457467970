.Carusel {
  width: 50%;
  height: 50%;
}
.Titulo {
  padding-left: 25%;
}
.Color-nav {
  background-color: #222121;
}
