A:link {
  text-decoration: none;
  color: #bfbfbf;
}
A:visited {
  text-decoration: none;
  color: #a6a6a6;
}
A:hover {
  color: #17c5d0 !important;
  text-decoration: none;
}
A:active {
  text-decoration: none;
  color: #404040;
}
